import { PageHeader, Alert, Input, Form, Checkbox, Button } from 'antd';
import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Login = ({ onLogin }) => {
  const [authError, setAuthError] = useState(undefined);
  const [authenticating, setAuthenticating] = useState(undefined);

  const onFinish = async (values) => {
    try {
      setAuthenticating(true);
      const signInAttempt = await Auth.signIn(values.username);
      if (signInAttempt.challengeName === 'CUSTOM_CHALLENGE') {
        // to send the answer of the custom challenge
        await Auth.sendCustomChallengeAnswer(signInAttempt, values.password);
        onLogin(true);
      } else {
        console.info('NOT CUSTOM_CHALLENGE', signInAttempt.challengeName);
        onLogin(false);
        setAuthError('Invalid server response');
      }
    } catch (e) {
      onLogin(false);
      console.log('something failed in the custom login');
      console.error(e);
      setAuthError(e.message);
    } finally {
      setAuthenticating(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    setAuthenticating(false);
    onLogin(false);
    setAuthError('');
  };

  const clearError = () => {
    setAuthError('');
  };

  const error = () => {
    if (authenticating) {
      return <Alert message="Logging in..." type="warning" showIcon />;
    } else if (authError != undefined && authError != '') {
      return <Alert message="Login failed" type="error" showIcon description={authError} />;
    }
  };

  return (
    <PageHeader style={{ margin: '0 auto', minWidth: 400 }} ghost={false} title="Spider X Updater">
      <Form
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={clearError}
      >
        <Form.Item
          label="Email address"
          name="username"
          rules={[{ required: true, message: 'Please input your email address!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button
            style={{ width: '100%' }}
            type="primary"
            htmlType="submit"
            disabled={authenticating}
          >
            Log in
          </Button>
        </Form.Item>

        {error()}
      </Form>
    </PageHeader>
  );
};

Login.propTypes = {
  onLogin: PropTypes.func,
};
export default Login;
