import { Auth } from 'aws-amplify';
import { Lambda } from 'aws-sdk';
import untar from 'js-untar';
import pako from 'pako';
import { useContext, useEffect, useState } from 'react';

import { FirmwareContext } from '../context/FirmwareContext';

import useAuth from './useAuth';
import useEnv from './useEnv';

const useFirmwaresForContext = (firmwareType, firmwareChannel) => {
  const [firmwareList, setFirmwareList] = useState(['Custom']);
  const [selectedChannel, setSelectedChannel] = useState(firmwareChannel);
  const [selectedFirmware, setSelectedFirmware] = useState(undefined);
  const [firmwareLink, setFirmwareLink] = useState({});
  const [firmwareBinary, setFirmwareBinary] = useState({});
  const [checking, setChecking] = useState(false);
  const [infoMessage, setInfoMessage] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [versionString, setVersionString] = useState(undefined);
  const [versionNumber, setVersionNumber] = useState(undefined);

  const env = useEnv();
  const auth = useAuth();

  useEffect(() => {
    // Invalidate any previous links
    setFirmwareLink({});

    setInfoMessage(undefined);
    setErrorMessage(undefined);

    if (env.env && auth.user && selectedFirmware && selectedFirmware != 'Custom') {
      const firmware = selectedFirmware;

      Auth.currentCredentials()
        .then((credentials) => {
          if (!credentials.authenticated) {
            return Promise.reject('Not authenticated');
          }

          return new Lambda({
            apiVersion: '2015-03-31',
            region: 'us-west-2',
            credentials: Auth.essentialCredentials(credentials),
          });
        })
        .then((lambda) => {
          setInfoMessage('Requesting firmware link...');

          let queryStringParameters = {};
          queryStringParameters.type = firmwareType;
          queryStringParameters.filetype = 'image-only';

          if (selectedChannel === 'release') {
            queryStringParameters.buildtype = 'release';
          } else {
            queryStringParameters.buildtype = 'promoted';
            queryStringParameters.channel = selectedChannel;
          }

          return lambda
            .invoke({
              FunctionName: `firmware-get-${env.env}`,

              Payload: JSON.stringify({
                pathParameters: {
                  version: firmware,
                },
                queryStringParameters,
              }),
            })
            .promise();
        })
        .then((a) => {
          let payload = JSON.parse(a.Payload);
          if (payload.statusCode < 200 || payload.statusCode >= 300) {
            throw payload.statusCode;
          }
          return JSON.parse(payload.body);
        })
        .then((response) => {
          if (!response.url) {
            throw 'url unavailable';
          }
          console.log('Got file url', response);
          setInfoMessage(undefined);
          setFirmwareLink({
            url: response.url,
            firmware: firmware,
          });
        })
        .catch((e) => {
          setInfoMessage(undefined);
          console.error('Failed to fetch firmware link', e);
          setErrorMessage('Failed to fetch firmware link (' + e + ')');
        });
    }
  }, [selectedFirmware, auth.user]);

  useEffect(() => {
    setFirmwareLink({});
    setFirmwareList(['Custom']);
    setSelectedFirmware(undefined);

    if (env.env && auth.user) {
      setInfoMessage(undefined);
      setErrorMessage(undefined);
      setChecking(false);
      var arr = [];

      Auth.currentCredentials()
        .then((credentials) => {
          if (!credentials.authenticated) {
            return Promise.reject('Not authenticated');
          }

          return new Lambda({
            apiVersion: '2015-03-31',
            region: 'us-west-2',
            credentials: Auth.essentialCredentials(credentials),
          });
        })
        .then((lambda) => {
          setChecking(true);
          setInfoMessage('Retrieving firmware list...');

          let buildtype;
          const filetype = 'image-only';

          if (selectedChannel === 'dev') {
            buildtype = 'dev';
          } else if (selectedChannel === 'release') {
            buildtype = 'release';
          } else {
            buildtype = 'promoted';
          }

          return lambda
            .invoke({
              FunctionName: `firmware-list-get-${env.env}`,
              Payload: JSON.stringify({
                queryStringParameters: {
                  channel: selectedChannel,
                  filetype: filetype,
                  buildtype: buildtype,
                },
              }),
            })
            .promise();
        })
        .then((a) => {
          let payload = JSON.parse(a.Payload);
          if (payload.statusCode < 200 || payload.statusCode >= 300) {
            throw payload.statusCode;
          }
          return JSON.parse(payload.body);
        })
        .then((response) => {
          arr = [...new Set(response.items[firmwareType])]; // Remove duplicates
          arr.sort((a, b) => calculateVersion(b) - calculateVersion(a));
        })
        .catch((err) => {
          console.log('Failed to download firmware list', err);
          if (err == 404) {
            if (selectedChannel === 'dev' || selectedChannel === 'release') {
              setErrorMessage('No firmware available in ' + selectedChannel + ' builds');
            } else {
              setErrorMessage('No firmware available in ' + selectedChannel + ' channel');
            }
          } else {
            setErrorMessage('Failed to download firmware list (' + err + ')');
          }
        })
        .finally(() => {
          setChecking(false);
          setInfoMessage(undefined);
          arr.push('Custom');
          setFirmwareList(arr);
        });
    }
  }, [selectedChannel, auth.user]);

  const calculateVersion = (version) => {
    const regex = /(\d+)\.(\d+)\.(\d+)(.(\d+))?/;
    const matched = regex.exec(version);
    if (matched) {
      const ver =
        (matched[1] << 24) +
        (matched[2] << 16) +
        (matched[3] << 8) +
        (matched[5] ? matched[5] << 0 : 0);
      console.log(version, ver);
      return ver;
    }
    return 0;
  };

  useEffect(() => {
    setFirmwareBinary({});
    if (firmwareLink.firmware === selectedFirmware && firmwareLink.url) {
      console.log('Getting firmware', firmwareLink.url);
      setInfoMessage('Downloading firmware...');
      setErrorMessage(undefined);
      let contentType;
      fetch(firmwareLink.url, { method: 'GET', headers: { 'Accept-Encoding': 'gzip' } })
        .then((response) => {
          contentType = response.headers.get('content-type');
          // application/octet-stream
          // application/x-tar
          if (response.status != 200) {
            throw response.status;
          }
          return response.arrayBuffer();
        })
        .then((gzipArrayBuffer) => {
          if (contentType.indexOf('tar') >= 0) {
            console.log('Unzip');
            return pako.ungzip(gzipArrayBuffer);
          }
          return gzipArrayBuffer;
        })
        .then((tarArrayBuffer) => {
          if (contentType.indexOf('tar') >= 0) {
            console.log('Untar');
            return untar(tarArrayBuffer.buffer);
          }
          return tarArrayBuffer;
        })
        .then((files) => {
          if (contentType.indexOf('tar') >= 0) {
            console.log(files);
            let file = files.find((file) => {
              return (
                file.name === `${firmwareType}-v${firmwareLink.firmware}.${env.env}.signed.bin`
              );
            });
            return file ? file.buffer : undefined;
          }
          return files;
        })
        .then((fw) => {
          if (!fw) {
            throw 'file not found';
          }
          setInfoMessage(undefined);
          setFirmwareBinary({
            data: fw,
            firmware: firmwareLink.firmware,
          });
          console.log('Got firmware', firmwareLink);
        })
        .catch((e) => {
          setInfoMessage(undefined);
          setErrorMessage('Failed to retrieve firmware (' + e + ')');
          console.error('Failed to retrieve firmware', e);
        });
    }
  }, [firmwareLink]);

  useEffect(() => {
    if (auth.user === null) {
      setSelectedFirmware(undefined);
      setFirmwareBinary({});
    }
  }, [auth.user]);

  return [
    setSelectedFirmware,
    firmwareBinary,
    setFirmwareBinary,
    versionString,
    versionNumber,
    firmwareList,
    selectedFirmware,
    selectedChannel,
    setSelectedChannel,
    firmwareLink,
    checking,
    infoMessage,
    errorMessage,
    setVersionString,
    setVersionNumber,
  ];
};

const useFirmwares = (firmwareType) => {
  const v = useContext(FirmwareContext);

  return v[firmwareType];
};
export { useFirmwaresForContext };
export default useFirmwares;
