import { LoadingOutlined } from '@ant-design/icons';
import { Button, Input, Select, Space, Tag } from 'antd';
import React, { useCallback } from 'react';

import useEnv from '../hooks/useEnv';
import useFirmwares from '../hooks/useFirmwares';

const { Option, OptGroup } = Select;

const FirmwareSelection = (props) => {
  const { firmwareType } = props;
  const env = useEnv();

  const [
    setSelectedFirmware,
    firmwareBinary,
    setFirmwareBinary,
    versionString,
    versionNumber,
    firmwareList,
    selectedFirmware,
    selectedChannel,
    setSelectedChannel,
    firmwareLink,
    checking,
    infoMessage,
    errorMessage,
    setVersionString,
    setVersionNumber,
  ] = useFirmwares(firmwareType);

  const detectVersion = (filename) => {
    const regex = /((\d+)\.(\d+)\.(\d+)(\.(\d+))?)/;
    const matched = regex.exec(filename);
    if (matched) {
      setVersionString(matched[1]);
      setVersionNumber(
        (matched[2] << 24) | (matched[3] << 16) | (matched[4] << 8) | (matched[6] | 0)
      );
    } else {
      setVersionString('0.0.0.0');
      setVersionNumber(0);
    }
  };

  const onFileChange = useCallback(
    (f) => {
      if (selectedFirmware === 'Custom') {
        setFirmwareBinary({});
        if (f.target.files.length > 0) {
          console.log('onFileChange', f.target.files[0].name);
          detectVersion(f.target.files[0].name);

          const reader = new FileReader();
          reader.onload = function () {
            console.log('loaded');
            setFirmwareBinary({
              data: this.result,
              firmware: selectedFirmware,
            });
          };
          reader.readAsArrayBuffer(f.target.files[0]);
        }
      }
    },
    [selectedFirmware]
  );

  const options = React.useMemo(
    () =>
      firmwareList.map((fw) => (
        <Option value={fw} key={fw}>
          {fw}
        </Option>
      )),
    [firmwareList]
  );

  const released_options = () => (
    <>
      <Option value="release">All Releases</Option>
      <OptGroup label="Promoted">
        <Option value="wiredupdate">Wired Update</Option>
        <Option value="canary">Canary</Option>
        <Option value="alpha">Alpha</Option>
        <Option value="beta">Beta</Option>
        <Option value="stable">Stable</Option>
        <Option value="factory">Factory</Option>
      </OptGroup>
    </>
  );

  const onChange = useCallback((e) => {
    console.log('onChange', e);
    detectVersion(e);
    setSelectedFirmware(e);
  }, []);

  const onChangeChannel = useCallback((e) => {
    console.log('onChangeChannel', e);
    setSelectedChannel(e);
  }, []);

  const info = () => {
    if (errorMessage !== undefined) {
      return <Tag color="error">{errorMessage}</Tag>;
    }
    if (infoMessage !== undefined) {
      return <Tag color="processing">{infoMessage}</Tag>;
    }
    if (firmwareList.length > 0 && selectedFirmware === undefined) {
      return <Tag color="warning">Select firmware</Tag>;
    }
    if (firmwareBinary.firmware === selectedFirmware) {
      return <Tag color="success">{versionString} loaded</Tag>;
    }
    return <Tag color="error">No firmware loaded</Tag>;
  };

  const link = () => {
    if (selectedFirmware !== 'Custom' && firmwareLink.url) {
      return (
        <Button size="small" type="dashed" href={firmwareLink.url}>
          Download a copy
        </Button>
      );
    }
  };

  const fileInput = () => {
    if (selectedFirmware === 'Custom') {
      return (
        <Input disabled={props.disabled} type="file" accept=".bin" onChange={onFileChange}></Input>
      );
    }
  };

  return (
    <>
      <Space direction="vertical">
        <Select
          showSearch
          style={{ width: 200 }}
          placeholder="Select a Firmware Channel"
          optionFilterProp="children"
          onChange={onChangeChannel}
          disabled={props.disabled}
          value={selectedChannel}
        >
          {released_options()}
        </Select>
        <Space direction="horizontal">
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select a Firmware Version"
            optionFilterProp="children"
            onChange={onChange}
            value={selectedFirmware}
            disabled={!firmwareList.length || props.disabled}
            suffixIcon={checking ? <LoadingOutlined /> : undefined}
          >
            {options}
          </Select>
          {link()}
          {info()}
        </Space>
        {fileInput()}
      </Space>
    </>
  );
};
export default FirmwareSelection;
