import { Alert, Button, Form, Modal, PageHeader, Progress, Space } from 'antd';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';

import useAuth from '../../hooks/useAuth';
import useFirmwares from '../../hooks/useFirmwares';
import useSpiderX from '../../hooks/useSpiderX';
import FirmwareSelection from '../FirmwareSelection';

const Firmware = () => {
  const spiderx = useSpiderX();

  const [setMainSel, mainBinary, setMain, mainVersionString, mainVersionNumber] =
    useFirmwares('main');
  const [
    setBootloaderSel,
    bootloaderBinary,
    setBootloader,
    bootloaderVersionString,
    bootloaderVersionNumber,
  ] = useFirmwares('bootloader');
  const [
    setCoprocessorSel,
    coprocessorBinary,
    setCoprocessor,
    coprocessorVersionString,
    coprocessorVersionNumber,
  ] = useFirmwares('coprocessor');

  const [mainImage, setMainImage] = useState();
  const [bootloaderImage, setBootloaderImage] = useState();
  const [coprocessorImage, setCoprocessorImage] = useState();

  const [form] = Form.useForm();
  const auth = useAuth();

  const later = (delay, value) => new Promise((resolve) => setTimeout(resolve, delay, value));
  const [signingOut, setSigningOut] = useState(false);
  const [userPresent, setUserPresent] = useState(true);

  const [stage, setStage] = useState('idle');
  const [error, setError] = useState();
  const [pass, setPass] = useState();

  const start = (event) => {
    setError();
    setPass();
    console.log('PROGRAM_BOOT');
    setStage('program_copro');
  };

  useEffect(() => {
    if (mainBinary.data) {
      const data = new Uint8Array(mainBinary.data);
      const offset = 0;
      const magic = new TextDecoder().decode(data.slice(offset, offset + 7));
      if (magic === '@VER002') {
        for (let i = 0; i < 32; i++) {
          data[offset + 32 + i] = 0xff;
        }
      }
      setMainImage(data.buffer);
    }
  }, [mainBinary]);

  useEffect(() => {
    if (bootloaderBinary.data) {
      const data = new Uint8Array(bootloaderBinary.data);
      const offset = 2 * 128 * 1024 - 4096;
      const magic = new TextDecoder().decode(data.slice(offset, offset + 7));
      if (magic === '@VER002') {
        for (let i = 0; i < 32; i++) {
          data[offset + 32 + i] = 0xff;
        }
      }
      setBootloaderImage(data.buffer);
    }
  }, [bootloaderBinary]);

  useEffect(() => {
    if (coprocessorBinary.data) {
      const data = new Uint8Array(coprocessorBinary.data);
      const offset = 512 * 1024 - 4096;
      const magic = new TextDecoder().decode(data.slice(offset, offset + 7));
      if (magic === '@VER002') {
        for (let i = 0; i < 32; i++) {
          data[offset + 32 + i] = 0xff;
        }
      }
      setCoprocessorImage(data.buffer);
    }
  }, [coprocessorBinary]);

  useEffect(() => {
    if (spiderx.connectedUsb && !spiderx.isDfu) {
      if (stage === 'usb') {
        console.log('REQUESTDFU');
        setStage('requestdfu');
        connectSerial();
      }
    }
  }, [spiderx.connectedUsb, spiderx.isDfu, stage]);

  useEffect(() => {
    if (spiderx.connectedUsb && spiderx.isDfu) {
      if (stage === 'usb' || stage === 'requestdfu') {
        console.log('DFU');
        setStage('dfu');
      }
    }
  }, [spiderx.connectedUsb, spiderx.isDfu, stage]);

  useEffect(() => {
    if (spiderx.connectedSerial) {
      if (stage === 'requestdfu') {
        console.log('Sending serial DFU command...');
        spiderx
          .commands()
          .rebootIntoBootloader()
          .catch((error) => {
            setError(error);
            console.log(error);
          });
      }
    }
  }, [spiderx.connectedSerial, stage]);

  useEffect(() => {
    if (!spiderx.connectedUsb && !spiderx.connectedSerial) {
      if (stage === 'requestdfu') {
        later(500).then(() => {
          console.log('connecting to dfu');
          setStage('dfu');
          spiderx.connectUsb().catch((error) => {
            console.log(error);
          });
        });
      } else if (stage === 'reconnect') {
        if (!navigator.userActivation.isActive) {
          setUserPresent(false);
          return;
        }
        console.log('reconnecting to dfu');
        setStage('program_main');
        spiderx.connectUsb().catch((error) => {
          console.log(error);
        });
      }
    }
  }, [stage, spiderx.connectedUsb, spiderx.connectedSerial, userPresent]);

  useEffect(() => {
    if (spiderx.connectedUsb && spiderx.isDfu && stage === 'program_copro') {
      console.log('Writing coprocessor image...');
      spiderx
        .pushImage(0x08160000, coprocessorImage, false)
        .then(() => later(500))
        .then(() => {
          setStage('program_boot');
        })
        .catch((error) => {
          setError(error);
          console.log('Update failed');
          setStage('idle');
          console.log('IDLE');
        });
    }
  }, [spiderx.connectedUsb, spiderx.connectedSerial, spiderx.isDfu, stage]);

  useEffect(() => {
    if (spiderx.connectedUsb && spiderx.isDfu && stage === 'program_boot') {
      console.log('Writing bootloader...');
      spiderx
        .pushImage(0x8000000, bootloaderImage, false)
        .then(() => later(500))
        .then(() => {
          if (bootloaderVersionNumber >= 0x050c0000) {
            console.log('Restarting Spider to update images...');
            setStage('waiting');
            console.log('WAITING');
            return spiderx.restart();
          } else {
            setStage('program_main');
            console.log('PROGRAM_MAIN');
            return Promise.resolve();
          }
        })
        .catch((error) => {
          setError(error);
          console.log('Update failed');
          setStage('idle');
          console.log('IDLE');
        });
    }
  }, [spiderx.connectedUsb, spiderx.connectedSerial, spiderx.isDfu, stage]);

  useEffect(() => {
    if (stage === 'waiting') {
      later(10000).then(() => {
        setStage('reconnect');
        console.log('RECONNECT');
      });
    }
  }, [stage]);

  useEffect(() => {
    if (spiderx.connectedUsb && spiderx.isDfu && stage === 'program_main') {
      console.log('Writing main image...');
      spiderx
        .pushImage(0x08040000, mainImage, false)
        .then(() => later(500))
        .then(() => {
          console.log('Restarting...');
          return spiderx.restart();
        })
        .then(() => {
          console.log('update complete');
          setPass(
            'Update complete. Leave Spider connected for 2 minutes to finish updating coprocessor'
          );
          setStage('idle');
          return Promise.resolve();
        })
        .catch((error) => {
          setError(error);
          console.log('Update failed');
          setStage('idle');
        });
    }
  }, [spiderx.connectedUsb, spiderx.connectedSerial, spiderx.isDfu, stage]);

  useEffect(() => {
    if (auth.user === null) {
      setSigningOut(true);
      window.location.reload();
    }
  }, [auth.user]);

  const logOut = async () => {
    try {
      setSigningOut(true);
      await Auth.signOut();
      window.location.reload();
    } catch (e) {
      console.log('error signing out: ', e);
    }
  };

  const openUsb = (event) => {
    console.log('Connecting to Spider over USB...');
    setStage('usb');
    setPass();
    setError();
    spiderx.connectUsb().catch((error) => {
      console.log(error);
    });
  };

  const openDfu = (event) => {
    setStage('dfu');
    connectSerial();
  };

  const connectSerial = () => {
    if (!spiderx.connectedSerial) {
      console.log('Opening serial port...');
      spiderx.connectSerial().catch((error) => {
        console.log(error);
        Modal.warning({
          title: 'Could not open serial port',
          centered: true,
          content: 'Close any tabs or applications that are already connected to the Spider',
        });
      });
    }
  };

  const closeDfu = (event) => {
    if (spiderx.isDfu) {
      console.log('Restarting Spider...');
      spiderx.restart().catch((error) => {
        console.log(error);
      });
    }
  };

  const info = () => {
    if (error) {
      return <Alert type="error" message={error} showIcon />;
    }
    if (stage === 'waiting') {
      return <Alert type="info" message="Waiting for bootloader to copy images..." showIcon />;
    }
    if (pass) {
      return <Alert type="success" message={pass} showIcon />;
    }
    if (!spiderx.connectedUsb || !spiderx.isDfu) {
      return <Alert type="warning" message="Waiting for Spider DFU mode..." showIcon />;
    }
    if (stage === 'program_copro') {
      return <Alert type="info" message="Programming coprocessor..." showIcon />;
    }
    if (stage === 'program_boot') {
      return <Alert type="info" message="Programing bootloader..." showIcon />;
    }
    if (stage === 'program_main') {
      return <Alert type="info" message="Programming main..." showIcon />;
    }
    if (
      bootloaderImage === undefined ||
      mainImage === undefined ||
      coprocessorImage === undefined
    ) {
      return <Alert type="warning" message="Please select and download firmware" showIcon />;
    }
    if (bootloaderVersionNumber >> 8 > mainVersionNumber >> 8) {
      return (
        <Alert type="error" message="Bootloader cannot be newer than main firmware" showIcon />
      );
    }
    if (coprocessorVersionNumber >> 8 > mainVersionNumber >> 8) {
      return (
        <Alert type="error" message="Coprocessor cannot be newer than main firmware" showIcon />
      );
    }
  };

  const progress = () => {
    if (stage === 'program_copro' || stage === 'program_boot' || stage === 'program_main') {
      return <Progress percent={spiderx.uploadProgress} status="active" />;
    }
  };

  const userHere = () => {
    setUserPresent(true);
  };

  const signout = signingOut ? <Alert message="Signing out..." type="warning" showIcon /> : null;

  const inProgress = () => {
    return stage !== 'idle' && stage !== 'usb' && stage !== 'requestdfu' && stage !== 'dfu';
  };

  return (
    <PageHeader ghost={false} title="Spider X Firmware Update">
      <Space direction="vertical" size="middle">
        <Form layout="vertical" form={form} name="device-firmware">
          <Space direction="vertical" size="middle">
            <Form.Item label="Bootloader Firmware" name="bootloader-firmware">
              <FirmwareSelection disabled={signingOut || inProgress()} firmwareType="bootloader" />
            </Form.Item>
            <Form.Item label="Main Firmware" name="main-firmware">
              <FirmwareSelection disabled={signingOut || inProgress()} firmwareType="main" />
            </Form.Item>
            <Form.Item label="Coprocessor Firmware" name="coprocessor-firmware">
              <FirmwareSelection disabled={signingOut || inProgress()} firmwareType="coprocessor" />
            </Form.Item>
            {info()}
            {progress()}
            <Form.Item>
              <Space direction="vertical">
                <Space direction="horizontal">
                  <Button
                    type="primary"
                    disabled={
                      signingOut ||
                      spiderx.connectedUsb ||
                      spiderx.connectedSerial ||
                      stage === 'reconnect' ||
                      stage === 'waiting'
                    }
                    onClick={openUsb}
                  >
                    Connect to Spider
                  </Button>
                  <Button
                    type="primary"
                    disabled={
                      signingOut ||
                      (!spiderx.connectedSerial && (!spiderx.connectedUsb || spiderx.isDfu))
                    }
                    onClick={openDfu}
                  >
                    Enter DFU mode
                  </Button>
                  <Button
                    type="primary"
                    disabled={
                      signingOut ||
                      !spiderx.connectedUsb ||
                      !spiderx.isDfu ||
                      stage === 'program_copro' ||
                      stage === 'program_boot' ||
                      stage === 'program_main' ||
                      stage === 'waiting' ||
                      stage === 'reconnect' ||
                      bootloaderImage === undefined ||
                      mainImage === undefined ||
                      coprocessorImage === undefined ||
                      bootloaderVersionNumber > mainVersionNumber ||
                      coprocessorVersionNumber > mainVersionNumber
                    }
                    onClick={start}
                  >
                    Flash Spider
                  </Button>
                  <Button
                    type="primary"
                    disabled={
                      signingOut ||
                      !spiderx.isDfu ||
                      stage === 'program_copro' ||
                      stage === 'program_boot' ||
                      stage === 'program_main' ||
                      stage === 'waiting'
                    }
                    onClick={closeDfu}
                  >
                    Exit DFU mode
                  </Button>
                  <Button type="primary" onClick={userHere} danger disabled={userPresent}>
                    Click to Complete Update
                  </Button>
                </Space>
              </Space>
            </Form.Item>
          </Space>
        </Form>

        <Space direction="horizontal">
          <Button type="primary" disabled={signingOut} onClick={logOut}>
            Log Out
          </Button>
          {signout}
        </Space>
      </Space>
    </PageHeader>
  );
};

export default Firmware;
