import React, { createContext } from 'react';
import { useCredentialsForContext } from '../hooks/useCredentials';

export const CredentialsContext = createContext([null, null, null]);

export const Provider = ({ children }) => {
  const credentials = useCredentialsForContext();

  return <CredentialsContext.Provider value={credentials}>{children}</CredentialsContext.Provider>;
};
