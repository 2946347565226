import React, { useState, createContext, useMemo } from 'react';
import deviceLib from '../lib/device';
import sxCommands from '../lib/sxCommands';

export const SpiderXContext = createContext();

const SXVendorId = 1155;

const useProvideSpiderX = () => {
  const [device, setDevice] = useState();
  const [serial, setSerial] = useState();
  const [isDfu, setIsDfu] = useState(false);
  const [uploadProgress, setProgress] = useState();

  const later = (delay, value) => new Promise((resolve) => setTimeout(resolve, delay, value));

  function promiseForEvent(eventTarget, eventType) {
    return new Promise((resolve) => {
      let eventHandler = (evt) => {
        resolve(evt);
        eventTarget.removeEventListener(eventTarget, eventHandler);
      };
      eventTarget.addEventListener(eventType, eventHandler);
    });
  }

  const connectUsb = () => {
    promiseForEvent(navigator.usb, 'disconnect').then((device) => {
      console.log('usb disconnected');
      setIsDfu(false);
      setDevice(undefined);
      setProgress(undefined);
    });

    if (device != undefined) return Promise.resolve();

    return navigator.usb
      .getDevices()
      .then((d) => {
        if (d.length > 0) {
          return d.find((dev) => dev.vendorId === SXVendorId);
        } else {
          return navigator.usb.requestDevice({ filters: [{ vendorId: SXVendorId }] });
        }
      })
      .then((d) => {
        const myDevice = { device_: d };

        if (myDevice.device_.productName.indexOf('DFU') >= 0) {
          console.log('dfu mode active');
          setIsDfu(true);
          return deviceLib.connect(d);
        } else {
          return myDevice;
        }
      })
      .then((d) => {
        if (d.device_.productName.indexOf('DFU') >= 0) {
          d.logProgress = (progress, done) => {
            let percent;
            if (progress === 0) {
              percent = 0;
            } else {
              percent = (progress / done) * 100;
            }

            setProgress(parseInt(percent));
          };
        }

        console.log('usb connected', d);
        setDevice(d);
      })
      .catch((error) => {
        return Promise.reject(error.message);
      });
  };

  const connectSerial = () => {
    promiseForEvent(navigator.serial, 'disconnect').then(() => {
      console.log('serial disconnected');
      setSerial(undefined);
    });

    if (serial != undefined) return Promise.resolve();

    let mySerial;
    return navigator.serial
      .getPorts()
      .then((ports) => {
        if (ports.length > 0) {
          return ports.find((s) => s.getInfo()['usbVendorId'] === SXVendorId);
        } else {
          return navigator.serial.requestPort({ filters: [{ usbVendorId: SXVendorId }] });
        }
      })
      .then((s) => {
        mySerial = s;
        return s.open({ baudRate: 115200 });
      })
      .then(() => {
        console.log('serial connected', mySerial);
        setSerial(mySerial);
      })
      .catch((error) => {
        return Promise.reject(error.message);
      });
  };

  const disconnect = async () => {
    console.log('disconnecting device');
    setProgress(undefined);

    if (device != undefined) {
      await device.device_
        .close()
        .then(() => {
          setDevice(undefined);
          setIsDfu(false);
          console.log('usb disconnected');
        })
        .catch((e) => {
          console.log('usb disconnect error: ', e);
        });
    }
    if (serial != undefined) {
      abortController.abort();
      await later(1000);
      serial
        .close()
        .then(() => {
          setSerial(undefined);
          console.log('serial disconnected');
        })
        .catch((e) => {
          console.log('serial disconnect error: ', e);
        });
    }
    return Promise.resolve();
  };

  const abortController = new AbortController();

  const getRxStream = (destination) => {
    var decoder = new TextDecoderStream();
    serial.readable
      .pipeThrough(decoder)
      .pipeTo(destination, { signal: abortController.signal })
      .catch((e) => {
        console.log(e);
      });
  };

  const connectedUsb = useMemo(() => !!device, [device]);
  const connectedSerial = useMemo(() => !!serial, [serial]);

  const restart = async () => {
    return device.restart();
  };

  const pushImage = async (start_address, firmware, restart) => {
    // Attempt to parse the DFU functional descriptor
    console.log('pushImage', start_address, firmware, restart);
    console.log('Device Description', device.properties);

    setProgress(0);

    device.startAddress = start_address;
    return device.do_download(device.properties.TransferSize, firmware, restart).then(
      () => {
        console.log('Done');
      },
      (error) => {
        console.error('pushImage error', error);
        return Promise.reject(error);
      }
    );
  };

  const commands = () => sxCommands(serial);

  return {
    isDfu,
    device,
    serial,
    connectUsb,
    connectSerial,
    disconnect,
    connectedUsb,
    connectedSerial,
    getRxStream,
    pushImage,
    restart,
    uploadProgress,
    commands,
  };
};

export const Provider = ({ children }) => {
  const spiderx = useProvideSpiderX();
  return <SpiderXContext.Provider value={spiderx}>{children}</SpiderXContext.Provider>;
};

export const Consumer = SpiderXContext.Consumer;
