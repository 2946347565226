import React from 'react';

const PageFooter = () => {
  return (
    <div className="App-footer">
      <a href={process.env.PUBLIC_URL + '/spider_x_bootloader_1.1.0.exe'}>
        Download Windows DFU Driver
      </a>
      <br />
      Spider X Updater - Spidertracks
    </div>
  );
};
export default PageFooter;
