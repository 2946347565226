import { Alert, Button, Form, Input, Modal, PageHeader, Progress, Space } from 'antd';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';

import useAuth from '../../hooks/useAuth';
import useCredentials from '../../hooks/useCredentials';
import useEnv from '../../hooks/useEnv';
import useSpiderX from '../../hooks/useSpiderX';

const ReHome = () => {
  const env = useEnv();
  const spiderx = useSpiderX();
  const credentials = useCredentials();

  const [spiderSerial, setSpiderSerial] = useState();
  const [spiderPin, setSpiderPin] = useState();
  const [serialValid, setSerialValid] = useState(false);
  const [serialError, setSerialError] = useState(false);

  const [stage, setStage] = useState('idle');
  const [form] = Form.useForm();
  const auth = useAuth();

  const later = (delay, value) => new Promise((resolve) => setTimeout(resolve, delay, value));
  const [signingOut, setSigningOut] = useState(false);
  const [userPresent, setUserPresent] = useState(true);

  const [error, setError] = useState();
  const [pass, setPass] = useState();
  const [image, setImage] = useState();

  const start = (event) => {
    setError();
    setPass();
    console.log('REQUEST');
    setStage('request');
  };

  useEffect(() => {
    if (spiderx.connectedUsb && !spiderx.isDfu) {
      if (stage === 'usb') {
        console.log('REQUESTDFU');
        setStage('requestdfu');
        connectSerial();
      }
    }
  }, [spiderx.connectedUsb, spiderx.isDfu, stage]);

  useEffect(() => {
    if (spiderx.connectedUsb && spiderx.isDfu) {
      if (stage === 'usb' || stage === 'requestdfu') {
        console.log('DFU');
        setStage('dfu');
      }
    }
  }, [spiderx.connectedUsb, spiderx.isDfu, stage]);

  useEffect(() => {
    if (spiderx.connectedSerial) {
      if (stage === 'requestdfu') {
        console.log('Sending serial DFU command...');
        spiderx
          .commands()
          .rebootIntoBootloader()
          .catch((error) => {
            setError(error);
            console.log(error);
          });
      }
    }
  }, [spiderx.connectedSerial, stage]);

  useEffect(() => {
    if (!spiderx.connectedUsb && !spiderx.connectedSerial) {
      if (stage === 'requestdfu') {
        later(500).then(() => {
          console.log('connecting to dfu');
          setStage('dfu');
          spiderx.connectUsb().catch((error) => {
            console.log(error);
          });
        });
      } else if (stage === 'reconnect') {
        if (!navigator.userActivation.isActive) {
          setUserPresent(false);
          return;
        }
        console.log('reconnecting to dfu');
        setStage('restart');
        spiderx.connectUsb().catch((error) => {
          console.log(error);
        });
      }
    }
  }, [stage, spiderx.connectedUsb, spiderx.connectedSerial, userPresent]);

  useEffect(() => {
    if (spiderx.connectedUsb && spiderx.isDfu && stage === 'request') {
      console.log('retrieving credentials...');
      credentials
        .getCredentials(spiderSerial, spiderPin)
        .then((image) => {
          setImage(image);
          setStage('reprogram');
          console.log('REPROGRAM');
        })
        .catch((error) => {
          setError(error);
          setImage();
          console.log(`Credentials failed (${error})`);
          setStage('idle');
          console.log('IDLE');
        });
    }
  }, [spiderx.connectedUsb, spiderx.connectedSerial, spiderx.isDfu, stage]);

  useEffect(() => {
    if (spiderx.connectedUsb && spiderx.isDfu && stage === 'reprogram') {
      console.log('writing credentials...');
      spiderx
        .pushImage(0x081e0000, image, false)
        .then(() => setImage())
        .then(() => later(500))
        .then(() => {
          console.log('Restarting Spider to update images...');
          setStage('waiting');
          console.log('WAITING');
          return spiderx.restart();
        })
        .catch((error) => {
          setError(error);
          setImage();
          console.log(`Update failed (${error})`);
          setStage('idle');
          console.log('IDLE');
        });
    }
  }, [spiderx.connectedUsb, spiderx.connectedSerial, spiderx.isDfu, stage]);

  useEffect(() => {
    if (stage === 'waiting') {
      later(7000).then(() => {
        setStage('reconnect');
        console.log('RECONNECT');
      });
    }
  }, [stage]);

  useEffect(() => {
    if (spiderx.connectedUsb && spiderx.isDfu && stage === 'restart') {
      console.log('Restarting...');
      spiderx
        .restart()
        .then(() => {
          console.log('update complete');
          setPass('Update complete');
          setStage('idle');
          console.log('IDLE');
        })
        .catch((error) => {
          setError(error);
          console.log('Update failed');
          setStage('idle');
          console.log('IDLE');
        });
    }
  }, [spiderx.connectedUsb, spiderx.connectedSerial, spiderx.isDfu, stage]);

  useEffect(() => {
    if (auth.user === null) {
      setSigningOut(true);
      window.location.reload();
    }
  }, [auth.user]);

  const resetPage = (event) => {
    form.resetFields();
    setImage();
    spiderx.disconnect();
    setStage('idle');
  };

  const logOut = async () => {
    try {
      setSigningOut(true);
      await Auth.signOut();
      window.location.reload();
    } catch (e) {
      console.log('error signing out: ', e);
    }
  };

  const openUsb = (event) => {
    console.log('Connecting to Spider over USB...');
    setStage('usb');
    console.log('USB');
    setPass();
    setError();
    spiderx.connectUsb().catch((error) => {
      //setError(error);
      console.log(error);
    });
  };

  const openDfu = (event) => {
    setStage('requestdfu');
    console.log('REQUESTDFU');
    connectSerial();
  };

  const connectSerial = () => {
    if (!spiderx.connectedSerial) {
      console.log('Opening serial port...');
      spiderx.connectSerial().catch((error) => {
        console.log(error);
        Modal.warning({
          title: 'Could not open serial port',
          centered: true,
          content: 'Close any tabs or applications that are already connected to the Spider',
        });
      });
    }
  };

  const closeDfu = (event) => {
    if (spiderx.isDfu) {
      console.log('Restarting Spider...');
      spiderx.restart().catch((error) => {
        setError(error);
        console.log(error);
      });
      setStage('idle');
      console.log('IDLE');
    }
  };

  const info = () => {
    if (error) {
      return <Alert type="error" message={error} showIcon />;
    }
    if (stage === 'waiting') {
      return <Alert type="info" message="Waiting for Spider to restart..." showIcon />;
    }
    if (pass) {
      return <Alert type="success" message={pass} showIcon />;
    }
    if (!serialValid) {
      return <Alert type="warning" message="Please enter your serial number and pin..." showIcon />;
    }
    if (!spiderx.connectedUsb || !spiderx.isDfu) {
      return <Alert type="warning" message="Waiting for Spider DFU mode..." showIcon />;
    }
    if (stage === 'request') {
      return <Alert type="info" message="Requesting credentials..." showIcon />;
    }
    if (stage === 'reprogram' && spiderx.uploadProgress !== undefined) {
      return <Progress percent={spiderx.uploadProgress} status="active" />;
    }
    if (stage === 'reconnect' || stage === 'restart') {
      return <Alert type="info" message="Programming..." showIcon />;
    }
    return <Alert type="info" message="Ready to program" showIcon />;
  };

  const userHere = () => {
    setUserPresent(true);
  };

  const signout = signingOut ? <Alert message="Signing out..." type="warning" showIcon /> : null;

  const inProgress = () => {
    return stage !== 'idle' && stage !== 'usb' && stage !== 'requestdfu' && stage !== 'dfu';
  };

  const validate_serial = (e) => {
    const { value } = e.target;
    const reg = /(^[A-Za-z0-9]{0,10}$)|(^[A-Za-z0-9]{10}-$)|(^([A-Za-z0-9]{10})-(\d{0,4})$)/;
    const result = reg.exec(value);

    setSerialError(!result);
    setPass();
    setError();

    if (result && result.length == 6 && value.length == 15) {
      setSpiderSerial(result[4]);
      setSpiderPin(result[5]);
      setSerialValid(true);
    } else {
      setSerialValid(false);
      setSpiderSerial(undefined);
      setSpiderPin(undefined);
    }
  };

  const error_text = serialError
    ? 'Please input your serial number and pin in the form ABCDEFGHIJ-1234'
    : null;

  const error_status = serialError ? 'error' : 'success';

  return (
    <PageHeader ghost={false} title="Spider X Re-Home">
      <Space direction="vertical" size="middle">
        <Alert
          type="info"
          message={
            <>
              Re-homing to <b>{env.env}</b> environment. Change environments using the link above
            </>
          }
        />

        <Form layout="vertical" form={form} name="rehome-device">
          <Alert type="warning" message={'This tool requires SX firmware v5.12 or later'} />
          <p />
          <Form.Item
            label="Enter SX Serial Number and Pin"
            name="serial"
            onChange={validate_serial}
            validateStatus={error_status}
            help={error_text}
            required
          >
            <Input disabled={signingOut || inProgress()} placeholder="e.g. ABCDEFGHIJ-1234" />
          </Form.Item>

          <Form.Item>
            <Space direction="vertical">
              {info()}
              <Space direction="horizontal">
                <Button
                  type="primary"
                  disabled={
                    signingOut ||
                    spiderx.connectedUsb ||
                    spiderx.connectedSerial ||
                    stage === 'reconnect' ||
                    stage === 'waiting'
                  }
                  onClick={openUsb}
                >
                  Connect to Spider
                </Button>
                <Button
                  type="primary"
                  disabled={
                    signingOut ||
                    (!spiderx.connectedSerial && (!spiderx.connectedUsb || spiderx.isDfu))
                  }
                  onClick={openDfu}
                >
                  Enter DFU mode
                </Button>
                <Button
                  type="primary"
                  disabled={
                    signingOut ||
                    !spiderx.connectedUsb ||
                    !spiderx.isDfu ||
                    !serialValid ||
                    stage === 'request' ||
                    stage === 'reprogram' ||
                    stage === 'waiting' ||
                    stage === 'restart' ||
                    stage === 'reconnect'
                  }
                  onClick={start}
                >
                  Update Certs
                </Button>
                <Button
                  type="primary"
                  disabled={
                    signingOut ||
                    !spiderx.isDfu ||
                    stage === 'request' ||
                    stage === 'reprogram' ||
                    stage === 'waiting' ||
                    stage === 'restart'
                  }
                  onClick={closeDfu}
                >
                  Exit DFU mode
                </Button>
                <Button type="primary" onClick={userHere} danger disabled={userPresent}>
                  Click to Complete Update
                </Button>
              </Space>
            </Space>
          </Form.Item>
        </Form>

        <Space direction="horizontal">
          <Button type="primary" disabled={signingOut} onClick={logOut}>
            Log Out
          </Button>
          {signout}
        </Space>
      </Space>
    </PageHeader>
  );
};

export default ReHome;
