export default (serial) => {
  const textEncoder = new TextEncoder();

  const write = (txt) => {
    const writer = serial.writable.getWriter();
    return writer.write(textEncoder.encode(txt)).then(() => writer.releaseLock());
  };

  const rebootIntoBootloader = () => {
    return write('POWER:Boot;');
  };

  const dumpSettings = () => {
    return write('SETTINGS:Log;');
  };

  const resetSettings = () => {
    return write('SETTINGS:Reset;');
  };

  return { rebootIntoBootloader, dumpSettings, resetSettings };
};
