/* eslint-env node */
module.exports.dev = {
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_LS69Erdyt',
    userPoolWebClientId: 'jr04no0hbbb11rlfaiku299up',
    authenticationFlowType: 'CUSTOM_AUTH',
    identityPoolId: 'us-west-2:fdfa4efb-39b5-4945-845e-6738214e02f4',
  },
};

module.exports.test = {
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_pL71vGYB7',
    userPoolWebClientId: '17r79us2hv4gkrttmf7k6hd13v',
    authenticationFlowType: 'CUSTOM_AUTH',
    identityPoolId: 'us-west-2:1fdee91a-d76b-4541-a3e7-fb7fc0b58763',
  },
};

module.exports.prod = {
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_Vp0BmLqw1',
    userPoolWebClientId: '1a745oi1bkj71d7chgpjqiadvl',
    authenticationFlowType: 'CUSTOM_AUTH',
    identityPoolId: 'us-west-2:d6222463-337c-4841-aa45-087812bc1b54',
  },
};
