import dfu from './dfu';
import dfuse from './dfuse';

function hex4(n) {
  let s = n.toString(16);
  while (s.length < 4) {
    s = '0' + s;
  }
  return s;
}

function hexAddr8(n) {
  let s = n.toString(16);
  while (s.length < 8) {
    s = '0' + s;
  }
  return '0x' + s;
}

function niceSize(n) {
  const gigabyte = 1024 * 1024 * 1024;
  const megabyte = 1024 * 1024;
  const kilobyte = 1024;
  if (n >= gigabyte) {
    return n / gigabyte + 'GiB';
  } else if (n >= megabyte) {
    return n / megabyte + 'MiB';
  } else if (n >= kilobyte) {
    return n / kilobyte + 'KiB';
  } else {
    return n + 'B';
  }
}
const connect = async (device_) => {
  const interfaces = dfu.findDeviceDfuInterfaces(device_);
  await fixInterfaceNames(device_, interfaces);
  let device = new dfu.Device(device_, interfaces[0]);

  await device.open();

  // Attempt to parse the DFU functional descriptor
  let desc = {};
  try {
    desc = await getDFUDescriptorProperties(device);
  } catch (error) {
    console.log('Unable to get Device Properties');
    return undefined;
    throw error;
  }

  if (desc && Object.keys(desc).length > 0) {
    device.properties = desc;

    if (desc.DFUVersion == 0x011a && device.settings.alternate.interfaceProtocol == 0x02) {
      device = new dfuse.Device(device.device_, device.settings);
      device.properties = desc;

      if (device.memoryInfo) {
        let totalSize = 0;
        for (let segment of device.memoryInfo.segments) {
          totalSize += segment.end - segment.start;
        }
        device.memorySummary = `Selected memory region: ${device.memoryInfo.name} (${niceSize(
          totalSize
        )})`;
        for (let segment of device.memoryInfo.segments) {
          let properties = [];
          if (segment.readable) {
            properties.push('readable');
          }
          if (segment.erasable) {
            properties.push('erasable');
          }
          if (segment.writable) {
            properties.push('writable');
          }
          let propertySummary = properties.join(', ');
          if (!propertySummary) {
            propertySummary = 'inaccessible';
          }

          device.memorySummary += `\n${hexAddr8(segment.start)}-${hexAddr8(
            segment.end - 1
          )} (${propertySummary})`;
        }
      }
    }
  }
  return device;
};

const fixInterfaceNames = async (device_, interfaces) => {
  // Check if any interface names were not read correctly
  if (interfaces.some((intf) => intf.name == null)) {
    // Manually retrieve the interface name string descriptors
    let tempDevice = new dfu.Device(device_, interfaces[0]);
    await tempDevice.device_.open();
    await tempDevice.device_.selectConfiguration(1);
    let mapping = await tempDevice.readInterfaceNames();
    await tempDevice.close();

    for (let intf of interfaces) {
      if (intf.name === null) {
        let configIndex = intf.configuration.configurationValue;
        let intfNumber = intf['interface'].interfaceNumber;
        let alt = intf.alternate.alternateSetting;
        intf.name = mapping[configIndex][intfNumber][alt];
      }
    }
  }
};

const getDFUDescriptorProperties = (device) => {
  // Attempt to read the DFU functional  descriptor
  // TODO: read the selected configuration's descriptor
  return device.readConfigurationDescriptor(0).then(
    (data) => {
      let configDesc = dfu.parseConfigurationDescriptor(data);
      let funcDesc = null;
      let configValue = device.settings.configuration.configurationValue;
      if (configDesc.bConfigurationValue == configValue) {
        for (let desc of configDesc.descriptors) {
          if (desc.bDescriptorType == 0x21 && desc.hasOwnProperty('bcdDFUVersion')) {
            funcDesc = desc;
            break;
          }
        }
      }

      if (funcDesc) {
        return {
          WillDetach: (funcDesc.bmAttributes & 0x08) != 0,
          ManifestationTolerant: (funcDesc.bmAttributes & 0x04) != 0,
          CanUpload: (funcDesc.bmAttributes & 0x02) != 0,
          CanDnload: (funcDesc.bmAttributes & 0x01) != 0,
          TransferSize: funcDesc.wTransferSize,
          DetachTimeOut: funcDesc.wDetachTimeOut,
          DFUVersion: funcDesc.bcdDFUVersion,
        };
      } else {
        return {};
      }
    },
    (error) => {}
  );
};

export default {
  connect,
  fixInterfaceNames,
  getDFUDescriptorProperties,
};
