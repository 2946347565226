import React, { useCallback } from 'react';
import { Menu, Layout, Radio } from 'antd';
import { useLocation, Link } from 'react-router-dom';
import useEnv from '../hooks/useEnv';

const { Header } = Layout;

const menu = [
  {
    name: 'SX Re-Home',
    path: '/re-home',
  },
  {
    name: 'SX Firmware',
    path: '/firmware',
  },
];

const menuItems = menu.map((item, index) => (
  <Menu.Item key={index}>
    <Link to={item.path}>{item.name}</Link>
  </Menu.Item>
));
const PageHeader = () => {
  const location = useLocation();
  const defaultSelectedItem = menu.findIndex((item) => item.path === location.pathname);

  const env = useEnv();
  const envChanged = useCallback(
    (e) => {
      env.envSetter(e.target.value);
    },
    [env.envSetter]
  );

  return (
    <Header
      style={{
        padding: '0 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '30px',
      }}
    >
      {/* <Row>
        <Col span={8}>
          <div className="logo" />
        </Col>
        <Col span={8} offset={8}>
        </Col>
      </Row>
      <Row> */}
      <Menu
        theme="dark"
        mode="horizontal"
        style={{ minWidth: 0, flex: 'auto' }}
        defaultSelectedKeys={[String(defaultSelectedItem)]}
      >
        {menuItems}
      </Menu>
      <Radio.Group defaultValue={env.env} buttonStyle="solid" onChange={envChanged}>
        <Radio.Button value="dev">Dev</Radio.Button>
        <Radio.Button value="test">Test</Radio.Button>
        <Radio.Button value="prod">Prod</Radio.Button>
      </Radio.Group>
      {/* </Row> */}
    </Header>
  );
};
export default PageHeader;
