import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Firmware from './components/Firmware';
import ReHome from './components/ReHome';

import { Provider as CredentialsProvider } from './context/CredentialsContext';
import { Provider as FirmwareProvider } from './context/FirmwareContext';

const Routes = () => (
  <Switch>
    <Route path="/re-home">
      <CredentialsProvider>
        <ReHome />
      </CredentialsProvider>
    </Route>
    <Route path="/firmware">
      <FirmwareProvider>
        <Firmware />
      </FirmwareProvider>
    </Route>
    <Route path="*" exact render={() => <Redirect to="/re-home" />} />
  </Switch>
);

export default Routes;
