import React, { createContext } from 'react';

import { useFirmwaresForContext } from '../hooks/useFirmwares';

export const FirmwareContext = createContext();

export const Provider = ({ children }) => {
  const mainFirmwares = useFirmwaresForContext('main', 'release');
  const bootloaderFirmwares = useFirmwaresForContext('bootloader', 'release');
  const coprocessorFirmwares = useFirmwaresForContext('coprocessor', 'release');

  return (
    <FirmwareContext.Provider
      value={{
        main: mainFirmwares,
        bootloader: bootloaderFirmwares,
        coprocessor: coprocessorFirmwares,
      }}
    >
      {children}
    </FirmwareContext.Provider>
  );
};
