import useLocalStorage from '../hooks/useLocalStorage';
import { Amplify, Auth } from 'aws-amplify';
import * as config from '../api/aws-exports';
import usePrevious from '../hooks/usePrevious';
import React, { createContext, useCallback, useEffect } from 'react';

export const EnvContext = createContext();

const EnvProvider = () => {
  let stage;
  switch (window.location.hostname) {
    case 'spiderx.util.dev.spidertracks.io':
      stage = 'dev';
      break;
    case 'spiderx.util.test.spidertracks.io':
      stage = 'test';
      break;
    case 'spiderx.util.spidertracks.io':
      stage = 'prod';
      break;
    default:
      stage = 'dev';
      break;
  }

  const [env, setEnv] = useLocalStorage('chosen-env', stage);

  if (!['dev', 'test', 'prod'].includes(env)) {
    setEnv(stage);
  }

  const prevEnv = usePrevious(env);

  const envSetter = useCallback(
    (e) => {
      if (['dev', 'test', 'prod'].includes(e)) {
        setEnv(e);
      } else {
        console.log('not a valid environment to set');
      }
    },
    [setEnv]
  );

  useEffect(() => {
    if (!!prevEnv && prevEnv != env) {
      Auth.signOut();
    }
    Amplify.configure(config[env]);
  }, [env]);

  return { env, envSetter };
};

export const Provider = ({ children }) => {
  const env = EnvProvider();
  return <EnvContext.Provider value={env}>{children}</EnvContext.Provider>;
};

export const Consumer = EnvContext.Consumer;
