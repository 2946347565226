import React from 'react';
import { Layout } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import AuthenticatorWrap from './components/AuthenticatorWrap';
import Routes from './Routes';
import PageHeader from './components/PageHeader';
import PageFooter from './components/PageFooter';
import './App.css';
import { Provider as SXProvider } from './context/SpiderXContext';
import { AuthProvider } from './context/AuthContext';
import { Provider as EnvProvider } from './context/EnvContext';

const { Content } = Layout;

function App() {
  return (
    <EnvProvider>
      <BrowserRouter>
        <AuthProvider>
          <SXProvider>
            <Layout className="App-layout">
              <PageHeader />
              <AuthenticatorWrap>
                <Content className="App-content">
                  <Routes />
                  <PageFooter />
                </Content>
              </AuthenticatorWrap>
            </Layout>
          </SXProvider>
        </AuthProvider>
      </BrowserRouter>
    </EnvProvider>
  );
}

export default App;
